<template>
  <div class="speaker-calendar">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <v-sheet height="64">
            <v-toolbar color="white" flat>
              <v-btn
                  @click="setToday"
                  class="mr-4"
                  color="grey darken-2"
                  outlined
                  v-text="$t('Today')"
              >
              </v-btn>
              <v-btn @click="prev" color="grey darken-2" fab small text>
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn @click="next" color="grey darken-2" fab small text>
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="900">
            <v-calendar
                :type="type"
                :weekdays="weekDays"
                color="primary"
                nav-visibility="visible"
                ref="calendar"
                v-model="focus"
                :events="events"
                @click:event="showEvent"
            ></v-calendar>
          </v-sheet>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog max-width="600px" persistent v-model="showSeminarDialog">
      <v-card>
        <v-card-title>
          <span class="headline" v-text="$t('SeminarInfos')" />
        </v-card-title>
        <v-card-text v-if="currentEvent">
          <template v-if="currentEvent.topic.name !== currentEvent.seminarName">
            <strong>{{ $t("Name") }}: </strong> {{ currentEvent.seminarName }}<br />
          </template>
          <strong>{{ $t("Topic") }}: </strong>{{ currentEvent.topic.name }}<br />
          <strong>{{ $t("Speaker") }}: </strong> {{ currentEvent.speakerName }}<br />
          <template v-if="currentEvent.coSpeakerName"><strong>{{ $t("CoSpeaker") }}: </strong>
            {{ currentEvent.coSpeakerName }}<br /></template>

          <strong>{{ $t("TimeSlot") }}:</strong><br />
          {{ formatDateTime(currentEvent.start, "DD.MM.Y HH:mm:ss") }} -
          {{ formatDateTime(currentEvent.end, "DD.MM.Y HH:mm:ss") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              @click="showSeminarDialog = false"
              color="blue darken-1"
              text
              v-text="$t('Close')"
          />
          <v-btn
              v-if="false"
              color="blue darken-1"
              @click="gotoSeminar"
              text
              v-text="$t('GotoSeminar')"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TimeSlotMixin from "@/mixins/TimeSlotMixin";
import SpeakerMixin from "@/mixins/SpeakerMixin";
import { formatDateTime } from "@/utils/dates";
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  name: "SpeakerCalendar",
  servicePrefix: "Speaker",
  mixins: [TimeSlotMixin, SpeakerMixin, ProfileMixin],
  components: {},
  data() {
    return {
      showSeminarDialog: false,
      weekDays: [1, 2, 3, 4, 5, 6, 0],
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      focus: "",
      events: [],
      currentEvent: null,
    };
  },
  watch: {
    speakerHandled() {
      if (this.speakerHandled === null) {
        return;
      }
      if (this.speakerActionHandled === "speakers/calendar") {
        const events = [];

        if (Array.isArray(this.speakerHandled["hydra:member"])) {
          this.speakerHandled["hydra:member"].map((item) => {
            let speaker = this.speakerById(item.speaker);
            let speakerName = `${ speaker.profile?.firstName } ${ speaker.profile?.lastName }`;
            if (speaker.profile?.title !== null) {
              speakerName = `${ speaker.profile?.title } ${ speakerName }`;
            }
            if (speaker.profile?.salutation !== null) {
              speakerName = `${ speaker.profile?.salutation } ${ speakerName }`;
            }
            let coSpeakerName = null;

            if (item.coSpeaker) {
              let coSpeaker = this.speakerById(item.coSpeaker);
              coSpeakerName = `${ coSpeaker.profile?.firstName } ${ coSpeaker.profile?.lastName }`;
              if (coSpeaker.profile?.title !== null) {
                coSpeakerName = `${ coSpeaker.profile?.title } ${ coSpeakerName }`;
              }
              if (coSpeaker.profile?.salutation !== null) {
                coSpeakerName = `${ coSpeaker.profile?.salutation } ${ coSpeakerName }`;
              }
            }

            events.push({
              "@id": item.id,
              name: coSpeakerName ? `${ speakerName } & ${ coSpeakerName } (${ item.seminar.name })` : `${ speakerName } (${ item.seminar.name })`,
              start: Date.parse(item.seminar.timeSlot.start),
              end: Date.parse(item.seminar.timeSlot.end),
              color: "#" + this.randomColor(),
              timed: false,
              topic: item.seminar.topic,
              seminarName: item.seminar.name,
              seminarId: item.seminar.id,
              speakerName: speakerName,
              coSpeakerName: coSpeakerName,
            });
            this.events = events;
          });
        }

        this.speakerHandled = null;
      }
    },
  },
  methods: {
    ...mapActions("speaker", {
      speakerAction: "action",
    }),
    formatDateTime,
    setToday() {
      this.focus = "";
    },
    prev() {
      let now = this.$refs.calendar.lastStart;
      var firstDay = new Date(now.year, now.month - 2, 1);
      var lastDay = new Date(now.year, now.month - 1, 0);
      this.loadCalendarData(firstDay, lastDay);
      this.$refs.calendar.prev();
    },
    next() {
      let now = this.$refs.calendar.lastStart;
      var firstDay = new Date(now.year, now.month, 1);
      var lastDay = new Date(now.year, now.month + 1, 0);
      this.loadCalendarData(firstDay, lastDay);
      this.$refs.calendar.next();
    },
    showEvent(event) {
      this.currentEvent = event.event;
      this.showSeminarDialog = true;
    },
    gotoSeminar() {
      let id = this.currentEvent.seminarId;
      this.currentEvent = null;
      this.$router.push({
        name: "SeminarShow",
        params: { id },
      });
    },
    randomColor() {
      return (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
    },
    loadCalendarData(start, end) {
      this.speakerAction({
        action: "speakers/calendar",
        data: null,
        params: [
          { key: "start", value: formatDateTime(start, "Y-MM-DD") },
          { key: "end", value: formatDateTime(end, "Y-MM-DD") },
        ],
        dtoResponse: true,
        method: 'GET'
      });
    },
  },
  mounted() {
    let now = this.$refs.calendar.getNow();
    var firstDay = new Date(now.year, now.month - 1, 1);
    var lastDay = new Date(now.year, now.month, 0);
    this.loadCalendarData(firstDay, lastDay);
  },
};
</script>
