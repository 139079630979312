import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import { diffDateTime } from "@/utils/dates";
import TimeSlotMixin from "@/mixins/TimeSlotMixin";
import AuthenticationMixin from "@/mixins/AuthenticationMixin";

export default {
  mixins: [TimeSlotMixin, AuthenticationMixin],
  data() {
    return {
      loadingFeeObjects: [],
      loadingSpeakerObjects: [],
    };
  },
  watch: {},
  computed: {
    ...mapFields('speaker', {
      speakerIds: 'allIds',
      speakerIdsCached: "cacheAllIds",
      speakerActionHandled: 'handleAction',
      speakerHandled: 'handled',
    }),
    ...mapFields('fee', {
      feeIds: 'allIds',
      feeIdsCached: "cacheAllIds",
    }),
    ...mapGetters('speaker', {
      speakerItems: 'list',
      findSpeaker: 'find'
    }),
    ...mapGetters('fee', {
      feeItems: 'list',
      findFee: 'find'
    }),
  },
  methods: {
    ...mapActions('speaker', {
      retrieveSpeaker: 'load',
      fetchAllSpeakers: 'fetchAll',
    }),
    ...mapActions('fee', {
      retrieveFee: 'load',
    }),
    speakerById(speakerId) {
      if (!this.speakerIdsCached.includes(speakerId) && !this.loadingSpeakerObjects.includes(speakerId)) {
        this.loadingSpeakerObjects.push(speakerId);
        this.retrieveSpeaker(decodeURIComponent(speakerId));
      }
      return this.findSpeaker(speakerId) ?? null;
    },
    feeById(feeId) {
      if (!this.feeIdsCached.includes(feeId) && !this.loadingFeeObjects.includes(feeId)) {
        this.loadingFeeObjects.push(feeId);
        this.retrieveFee(decodeURIComponent(feeId));
      }
      return this.findFee(feeId) ?? null;
    },
    getSpeakerFee(speakerId, tenantId) {
      let speaker = this.speakerById(speakerId);
      let speakerFee = null;
      speaker.fees.forEach(feeId => {
        let fee = this.feeById(feeId);
        if (fee !== null && fee.tenant === tenantId) {
          if (speakerFee === null) {
            speakerFee = fee;
          } else {
            if (speakerFee.unit === 'hour' && fee.unit === 'day') {
              // day better condition then hour
              speakerFee = fee;
            }
          }
        }
      });
      return speakerFee;
    },
    calculateFeeForTimeSlot(speakerId, tenantId, timeSlotId) {
      let total = 0;
      let fee = this.getSpeakerFee(speakerId, tenantId);
      if (fee !== null) {
        let timeSlot = this.resolveTimeSlot(timeSlotId);
        if (typeof timeSlot === "undefined") {
          return total;
        }
        let diff = diffDateTime(timeSlot.start, timeSlot.end);
        if (fee.unit === "hour") {
          let hours = diff.hours();
          if (diff.minutes() > 15) {
            hours++;
          }
          if (diff.days() > 0) {
            hours += (diff.days() * 24);
          }
          total += fee.rate * hours;
        } else {
          if (diff.days() > 0) {
            total += (diff.days() * fee.rate);
          } else {
            total += fee.rate;
          }
        }
      }
      return total;
    },
    async getSpeakersForActiveTenant() {
      return await this.fetchAllSpeakers({
        params: {
          'tenantUsers.tenant': this.userData.activeTenant['@id']
        }
      });
    },
  }
};
